export enum IconNames {
  LOGIN = "login",
  LOGOUT = "logout",
  FULL_ALERT = "full_alert",
  EMPTY_ALERT = "empty_alert",
  ARROW_LEFT = "arrow_left",
  ARROW_RIGHT = "arrow_right",
  ARROW_DOWN = "arrow_down",
  BIN = "bin",
  CHECK = "check",
  COPY = "copy",
  CLOSE = "close",
  DOWNLOAD = "download",
  DOWNLOAD_CLOUD = "download_cloud",
  EXIT = "exit",
  FILE = "file",
  HIGH_PERFORMANCE = "high_performance",
  MAINSTREAM = "mainstream",
  ULTRA_LOW_POWER = "mainstream",
  WIRELESS = "wireless",
  STAR = "high_performance",
  KEY = "key",
  SEARCH = "search",
  LOGO = "logo",
  TOOLS = "tools",
  BOX = "box",
  ARROW_UP_FILL = "arrow_up_fill",
  ARROW_DOWN_FILL = "arrow_down_fill",
  ARROW_RIGHT_FILL = "arrow_right_fill",
  ARROW_LEFT_FILL = "arrow_left_fill",
  MENU = "menu",
}
