import React from "react";
import styles from "./Icon.module.scss";

export type IconProps = {
  iconName: string;
  color: string;
  width?: number | string;
  height?: number | string;
};

const Icon = ({ iconName, color, width, height }: IconProps) => {
  return (
    <svg style={{ color }} width={width ?? 24} height={height ?? 24}>
      <use href={`/icons.svg#${iconName}`} className={styles.icon} />
    </svg>
  );
};

export default Icon;
