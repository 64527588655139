import classNames from "classnames";
import styles from "./AsyncBuilderPanel.module.scss";
import { AsyncBuilderItem } from "../asyncBuilderItem/AsyncBuilderItem";
import { useDispatch, useSelector } from "react-redux";
import { getFileNameList, getTraceIdList } from "@/utils/selectors";
import { labels } from "@/utils/labels";
import { setClickedTraceId } from "@/features/traceId/traceIdSlice";
import { useRouter } from "next/router";
import { Route } from "@/types/types";
import { SDK } from "@/types/aws";

export type AsyncBuilderPanelProps = {
  open: boolean;
  title: string;
  downloadQueryValuesArray: SDK[];
};

export const AsyncBuilderPanel = ({
  open,
  title,
  downloadQueryValuesArray,
}: AsyncBuilderPanelProps) => {
  const traceIdList = useSelector(getTraceIdList);
  const fileNameList = useSelector(getFileNameList);

  const dispatch = useDispatch();
  const router = useRouter();

  const handleClick = (index: number) => {
    const clickedItemTraceId = traceIdList[index];
    dispatch(setClickedTraceId(clickedItemTraceId));
    router.push(Route.DOWNLOAD);
  };

  return (
    <aside className={classNames(styles.container, { [styles.open]: open })}>
      <p className={styles.title}>{title}</p>
      <div className={styles.contentWrapper}>
        <p className={styles.text}>
          {downloadQueryValuesArray.length > 0
            ? labels.BUILD_IN_PROGRESS
            : labels.NO_BUILD}
        </p>
        {downloadQueryValuesArray.length > 0 && (
          <ul className={styles.list}>
            {downloadQueryValuesArray &&
              downloadQueryValuesArray.map((item, index) => {
                return (
                  <AsyncBuilderItem
                    percentage={item.percentage!}
                    onClick={() => handleClick(index)}
                    fileName={fileNameList[index]}
                  />
                );
              })}
          </ul>
        )}
      </div>
    </aside>
  );
};
