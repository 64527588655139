import styles from "./Layout.module.scss";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "@/types/types";
import {
  getErrorCode,
  getErrorDescription,
  getPrevUrl,
  getEmail,
} from "@/utils/selectors";
import { ErrorBanner } from "../errorBanner/ErrorBanner";
import { cleanAllStates } from "@/utils/function";
import { Footer } from "../footer/Footer";
import { HamburgerMenu } from "../hamburgerMenu/HamburgerMenu";
import { setCookie } from "cookies-next";
import { Header } from "../header/Header";

export type LayoutProps = {
  children: React.ReactNode;
  hasGradientBg?: boolean;
};

export const Layout = ({ children, hasGradientBg }: LayoutProps) => {
  const router = useRouter();
  const isDownloadPage = router.pathname === Route.DOWNLOAD;
  const dispatch = useDispatch();
  const prevUrl = useSelector(getPrevUrl);
  const errorCode = useSelector(getErrorCode);
  const errorDescription = useSelector(getErrorDescription);
  const userEmail = useSelector(getEmail);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeHistoryChange = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (prevUrl === Route.DOWNLOAD) {
        cleanAllStates(dispatch);
      }
    };

    router.events.on("beforeHistoryChange", handleBeforeHistoryChange);

    return () => {
      router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
    };
  }, [router, prevUrl]);

  useEffect(() => {
    if (userEmail) {
      setCookie("userLoggedIn", true);
    } else {
      setCookie("userLoggedIn", false);
    }
  }, [userEmail]);

  return (
    <>
      <Header isMenuOpen={isMenuOpen} onToggleMenu={setIsMenuOpen} />
      <main
        className={classNames(styles.container, {
          [styles.gradientBg]: hasGradientBg,
          [styles.dFlex]: isDownloadPage,
        })}
      >
        <HamburgerMenu isOpen={isMenuOpen} />
        <div className={styles.wrapper}>
          {errorCode && (
            <ErrorBanner isVisible={true} errorCode={errorCode} hasCloseAction>
              {errorDescription}
            </ErrorBanner>
          )}
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
};
