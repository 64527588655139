import { useEffect } from "react";

interface Props {
  ref: React.MutableRefObject<any>;
  onClickOutside: () => void;
  deps?: Array<any>;
}

export const useOutsideAlerter = ({ ref, onClickOutside, deps }: Props) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, [...(deps ?? [])]]);
};
export default useOutsideAlerter;
