import styles from "./AsyncBuilderItem.module.scss";
import { Spinner } from "@/components/spinner/Spinner";
import Icon from "@/components/icon/Icon";
import { IconNames } from "@/types/iconNames";
import { labels } from "@/utils/labels";
import { useDispatch } from "react-redux";

export type AsyncBuilderItemProps = {
  percentage: number;
  fileName: string;
  onClick: Function;
};

export const AsyncBuilderItem = ({
  percentage,
  fileName,
  onClick,
}: AsyncBuilderItemProps) => {
  return (
    <li className={styles.container}>
      <p className={styles.paragraph}>
        <span className={styles.title}>
          {percentage !== 100
            ? `Building your ${fileName}`
            : `Build ${fileName} completed!`}
        </span>
        {percentage !== 100 ? (
          <span className={styles.text}>{labels.WAIT}</span>
        ) : (
          <button
            type="button"
            className={styles.link}
            onClick={() => onClick()}
          >
            {labels.READY_DOWNLOAD_SDK}
          </button>
        )}
      </p>
      <div className={styles.column}>
        {percentage !== 100 ? (
          <>
            <Spinner size="small" />
            <span className={styles.percentage}>{percentage}%</span>
          </>
        ) : (
          <span className={styles.iconContainer}>
            <Icon iconName={IconNames.CHECK} color={"var(--color-neutral-01"} />
          </span>
        )}
      </div>
    </li>
  );
};
