import { useState } from "react";
import styles from "./Popover.module.scss";
import * as PopoverPrimitive from "@radix-ui/react-popover";

type PopoverProps = {
  content: string;
  children: React.ReactNode;
  sideOffset?: number;
  side?: "left" | "right" | "top" | "bottom";
};

export const Popover = ({
  content,
  children,
  side = "right",
  sideOffset = 10,
}: PopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
      <PopoverPrimitive.Trigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          className={styles.popoverContent}
          sideOffset={sideOffset}
          side={side}
        >
          <p>{content}</p>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};
