import React from "react";
import { Table } from "@radix-ui/themes";
import styles from "./DynamicTable.module.scss";
import { getformattedDate } from "@/utils/date";
import { Clipboard } from "@/components/clipboard/Clipboard";

export type TableProps<T> = {
  title: string;
  emptyTableMessage?: string;
  columns: TableColumn[];
  data: T;
  children?: React.ReactNode;
};

export type TableColumn = {
  type: "action" | "column";
  name: string;
  columnName?: string;
  icon?: string;
  isClipboard?: boolean;
  isDate?: boolean;
  element?: React.ReactNode;
  action?: (e: any) => void;
};

export type TableCell = {
  value?: string;
  tableColumn?: TableColumn;
  item?: any;
};

const TableCellDefault = ({ value, tableColumn }: TableCell) => {
  if (tableColumn?.isClipboard) {
    return (
      <Table.Cell className={`${styles.tableBodyCell}`}>
        {value} <Clipboard text={value ?? ""} />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell className={`${styles.tableBodyCell}`}>
      {value && tableColumn?.isDate ? getformattedDate(value) : value}
    </Table.Cell>
  );
};

const TableCellWithActionIcon = ({ tableColumn, item }: TableCell) => {
  return (
    <Table.Cell className={`${styles.tableBodyCell} ${styles.isAction}`}>
      <button type="button" onClick={tableColumn?.action?.bind(this, item)}>
        {tableColumn?.element}
      </button>
    </Table.Cell>
  );
};

const EmptyTableMessage = (prop: { message: string }) => {
  return (
    <div className={styles.tableParagraph}>
      <p>{prop.message}</p>
    </div>
  );
};

export const DynamicTable = ({
  title,
  emptyTableMessage,
  columns,
  data,
  children,
}: TableProps<any>) => {
  return (
    <>
      <div className={styles.tableHeader}>{title}</div>
      <Table.Root>
        <Table.Header>
          <Table.Row className={styles.tableHeaderRow}>
            {columns.map((column) => {
              return (
                <Table.ColumnHeaderCell className={styles.tableHeaderCell}>
                  {column.name}
                </Table.ColumnHeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item: any) => {
            return (
              <Table.Row className={styles.tableBodyRow}>
                {columns.map((column) => {
                  if (
                    column?.columnName &&
                    item.hasOwnProperty(column.columnName)
                  ) {
                    return (
                      <TableCellDefault
                        value={item[column.columnName]}
                        tableColumn={column}
                      />
                    );
                  }
                  if (column.type === "action") {
                    return (
                      <TableCellWithActionIcon
                        tableColumn={column}
                        item={item}
                      />
                    );
                  }
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
      {data.length === 0 && emptyTableMessage && (
        <EmptyTableMessage message={emptyTableMessage} />
      )}
      {children && <div className={styles.tableFooter}>{children}</div>}
    </>
  );
};
