import * as APITypes from "@/types/aws";

type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onSDKProgressUpdate =
  /* GraphQL */ `subscription onSDKProgressUpdate($id: ID!) {
  onSDKProgressUpdate(id: $id) {
    id
    percentage
    status
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.onSDKProgressUpdateSubscriptionVariables,
    APITypes.onSDKProgressUpdateSubscription
  >;
