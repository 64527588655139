import styles from "./ErrorBanner.module.scss";
import classNames from "classnames";
import { useState } from "react";
import Icon from "@/icon/Icon";
import { IconNames } from "@/types/iconNames";

export type ErrorBannerProps = {
  errorCode?: number;
  hasMarginBottom?: boolean;
  isVisible: boolean;
  children: React.ReactNode;
  hasCopyAction?: boolean;
  hasCloseAction?: boolean;
  closeAction?: Function;
  additionalClassName?: string;
};

export const ErrorBanner = ({
  errorCode,
  hasMarginBottom,
  isVisible,
  children,
  hasCopyAction,
  hasCloseAction,
  closeAction,
  additionalClassName,
}: ErrorBannerProps) => {
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessMessage(true);
    } catch (error) {
      setSuccessMessage(false);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    isBannerVisible && (
      <div
        className={classNames(styles.container, additionalClassName, {
          [styles.marginBottom]: hasMarginBottom && isVisible,
          [styles.visible]: isVisible,
        })}
      >
        <div className={styles.errorTitleContainer}>
          <span className={styles.iconContainer}>
            <Icon
              iconName={IconNames.FULL_ALERT}
              color={"#692326"}
              width={24}
              height={24}
            />
          </span>
          <strong className={styles.strong}>
            Error {errorCode ? errorCode : "500"}:
          </strong>
        </div>

        <p className={styles.children}>{children}</p>
        {hasCopyAction && (
          <button
            type="button"
            className={styles.copyBtn}
            onClick={() => copyToClipboard(children as string)}
          >
            <Icon
              iconName={successMessage ? IconNames.CHECK : IconNames.COPY}
              color={"#03053D"}
            />
          </button>
        )}
        {hasCloseAction && (
          <button type="button" onClick={handleCloseBanner}>
            <Icon iconName={IconNames.CLOSE} color={"#692326"} />
          </button>
        )}
      </div>
    )
  );
};
