import { useEffect, useRef, useState } from "react";
import styles from "./Login.module.scss";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Icon from "@/icon/Icon";
import { useGetLoginDataQuery } from "@/pages/api/apiSlice";
import { useModalToggle } from "@/components/modal/Modal";
import { resetAllLogin, setEmail } from "@/features/login/loginSlice";
import { cleanAllStates } from "@/utils/function";
import useOutsideAlerter from "@/hooks/useClickOutside";
import { IconNames } from "@/types/iconNames";
import { SubscriptionKeys } from "@/components/subscriptionKeys/SubscriptionKeys";
import { setCookie } from "cookies-next";

export type LoginProps = {};

export const Login = ({}: LoginProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL || "";

  const { data: loginData } = useGetLoginDataQuery();

  const router = useRouter();
  const dispatch = useDispatch();

  const [value, toggle] = useModalToggle(false);

  useEffect(() => {
    if (loginData) {
      dispatch(setEmail(loginData.Email));
      setIsAuth(true);
    } else {
      dispatch(resetAllLogin());
      setIsAuth(false);
    }
  }, [loginData]);

  const handleClick = () => {
    if (isAuth) {
      router
        .push({
          pathname: `${baseUrl}/sdk/api/v1/logout/saml`,
          query: { shallow: true },
        })
        .then(() => {
          setIsAuth(false);
          dispatch(resetAllLogin());
          cleanAllStates(dispatch);
        });
    } else {
      router
        .push({
          pathname: `${baseUrl}/sdk/api/v1/login/saml`,
          query: { shallow: true },
        })
        .then(() => {
          setIsAuth(true);
        });
    }
  };

  useOutsideAlerter({
    ref: containerRef,
    onClickOutside: () => setIsOpen(false),
  });

  const SubscriptionKeysMenuItem = () => {
    return (
      <button
        type="button"
        className={styles.logBtn}
        onClick={() => {
          setIsOpen(false);
          toggle();
        }}
      >
        <span className={styles.logText}>Subscription Key</span>
        <span className={styles.logIcon}>
          <Icon iconName={IconNames.KEY} color={"var(--color-neutral-10)"} />
        </span>
      </button>
    );
  };

  return (
    <div ref={containerRef}>
      <button
        className={styles.userBtn}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        // ref={containerRef}
      >
        <p className={styles.iconContainer}>
          <Icon
            iconName={isAuth ? IconNames.LOGOUT : IconNames.LOGIN}
            color={"var(--color-neutral-01)"}
            width={24}
            height={24}
          />
        </p>

        {isAuth && loginData?.DisplayName && (
          <span className={styles.name}>{loginData?.DisplayName}</span>
        )}
      </button>

      {isOpen && (
        <div
          className={classnames(styles.logContainer, {
            [styles.open]: isOpen,
          })}
        >
          <button
            type="button"
            className={styles.logBtn}
            onClick={() => {
              handleClick();
            }}
          >
            <span className={styles.logText}>
              {isAuth ? "Log out" : "Log in"}
            </span>
            <span className={styles.logIcon}>
              <Icon
                iconName={IconNames.EXIT}
                color={"var(--color-neutral-10)"}
              />
            </span>
          </button>
          {isAuth && <SubscriptionKeysMenuItem />}
        </div>
      )}

      {isAuth && value && (
        <SubscriptionKeys statusModal={value} toggle={toggle} />
      )}
    </div>
  );
};
