import Icon from "@/components/icon/Icon";
import { IconNames } from "@/types/iconNames";
import styles from "./HamburgerMenuButton.module.scss";

export type HamburgerMenuButtonProps = {
  isMenuOpen: boolean;
  onToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
  additionalClassName?: string;
};

export const HamburgerMenuButton = ({
  isMenuOpen,
  onToggleMenu,
  additionalClassName,
}: HamburgerMenuButtonProps) => {
  return (
    <button
      type="button"
      onClick={() => onToggleMenu(!isMenuOpen)}
      className={additionalClassName}
    >
      <span className={styles.iconContainer}>
        <Icon
          iconName={!isMenuOpen ? IconNames.MENU : IconNames.CLOSE}
          color="var(--color-neutral-01)"
        />
      </span>
    </button>
  );
};
