import { labels } from "@/utils/labels";
import styles from "./HamburgerMenu.module.scss";
import { Route } from "@/types/types";
import classNames from "classnames";
import { HamburgerMenuItem } from "./components/hamburgerMenuItem/HamburgerMenuItem";

export type HamburgerMenuProps = {
  isOpen: boolean;
};

export const HamburgerMenu = ({ isOpen }: HamburgerMenuProps) => {
  return (
    <>
      <div
        className={classNames(styles.overlay, { [styles.open]: isOpen })}
      ></div>
      <div className={classNames(styles.sidebar, { [styles.open]: isOpen })}>
        <HamburgerMenuItem text={labels.SDK_BUILDER} path={Route.HOME} />
        <span className={styles.subtitle}>{labels.ADMIN_TOOLS}</span>
        <nav>
          <ul>
            <li>
              <HamburgerMenuItem
                text={labels.TOOL_DOWNLOAD}
                path={Route.TOOL}
              />
            </li>
            <li>
              <HamburgerMenuItem
                text={labels.SECURITY_ROLES}
                path={Route.SECURITY}
              />
            </li>
            <li>
              <HamburgerMenuItem
                text={labels.USER_MANAGEMENT}
                path={Route.USER}
              />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
