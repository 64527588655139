import Icon from "@/icon/Icon";
import styles from "./BinIcon.module.scss";
import { IconNames } from "@/types/iconNames";

export const BinIcon = () => {
  return (
    <div className={styles.binIcon}>
      <Icon iconName={IconNames.BIN} color={"var(--color-neutral-01)"} />
    </div>
  );
};
