import classNames from "classnames";
import styles from "./Spinner.module.scss";

export type SpinnerProps = {
  size: "xsmall" | "small" | "large";
  containerClassname?: string;
  percentage?: string;
};

export const Spinner = ({
  size,
  containerClassname,
  percentage,
}: SpinnerProps) => {
  return (
    <div className={styles.container}>
      <span
        className={classNames(styles.loader, styles[size], containerClassname)}
      ></span>
      {percentage && <span className={styles.percentage}>{percentage}%</span>}
    </div>
  );
};
