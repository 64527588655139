import { GraphQLAuthMode } from "@aws-amplify/core/dist/esm/singleton/API/types";

export interface AmplifyConfig {
  API: {
    GraphQL: {
      endpoint: string;
      region: string;
      defaultAuthMode: GraphQLAuthMode;
      apiKey: string;
    };
  };
}

export const config: AmplifyConfig = {
  API: {
    GraphQL: {
      endpoint:
        "https://c2ihq62horf4rkiok5eje235qu.appsync-api.eu-west-1.amazonaws.com/graphql",
      region: "eu-west-1",
      defaultAuthMode: "apiKey",
      apiKey: "da2-uh2jpzw5wbgk5izpbu6iezuch4",
    },
  },
};

export const amplifyConfig = config;
