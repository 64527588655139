import { ReactNode, useCallback, useState } from "react";
import styles from "./Modal.module.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Popover } from "@/components/popover/Popover";
import Icon from "@/icon/Icon";
import { IconNames } from "@/types/iconNames";

export type ModalProps = {
  status: boolean;
  title?: string;
  description?: string;
  children: ReactNode;
  popoverText?: string;
  handleModalStatus?: () => void;
};

export const useModalToggle = (initialValue = false): [boolean, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const setToggle = useCallback(() => {
    setValue((status) => !status);
  }, []);

  return [value, setToggle];
};

export const Modal = ({
  status = false,
  handleModalStatus,
  title,
  description,
  children,
  popoverText,
}: ModalProps) => {
  return (
    <Dialog.Root open={status}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay}>
          <Dialog.Content className={styles.dialogContent}>
            <div className={styles.headerContainer}>
              <Dialog.Title className={styles.title}>{title}</Dialog.Title>
              {popoverText && (
                <Popover content={popoverText} side="top">
                  <span className={styles.imageContainer}>
                    <Icon
                      iconName={IconNames.EMPTY_ALERT}
                      color={"var(--color-neutral-10"}
                    />
                  </span>
                </Popover>
              )}
              <Dialog.Close
                onClick={handleModalStatus}
                className={styles.close}
                aria-label="Close"
              >
                <div>
                  <Icon iconName={IconNames.CLOSE} color={"#03234B"} />
                </div>
              </Dialog.Close>
            </div>

            {description && (
              <Dialog.Description className={styles.description}>
                {description}
              </Dialog.Description>
            )}
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
